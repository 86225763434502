/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always refeence jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var Sage = {
    'common': {
      init: function() {

        // Announcement
        $('#close-announcement').click(function () {
          $(this).parent('.site-announcement').hide();
        });

        var cookieStatus = Cookies.get('Annoucement');

        $('#close-announcement').on('click', function(e){
          Cookies.set('Annoucement', 'viewed', {expires:30});
        });

        if (cookieStatus !== ('viewed') || cookieStatus === undefined ) {
          $('.site-announcement').show();
        } else {
          $('.site-announcement').addClass('closed');
          $('.site-announcement').hide();
        }

        // Toggle Navigation
        function toggleNavBar() {
          $('.navbar-menu').slideToggle(400);
        }
        $('#menu-toggle').click( toggleNavBar );

        // Restaurant Menu 
        $(".js-nav-menu .sub-menu").each(function() {
          $(this).wrapInner("<div class='vertical-wrapper'></div>");
        });
        
        $('.primary-nav').find('.js-nav-menu').prepend("<i class='ico ico-close modal-close'></i>");


        function modalOn(modalOnState) {
          $('body').addClass('noscroll ' + modalOnState );
          $('body').bind('touchmove', function(e){e.preventDefault();});

        }
        function modalOff(modalOnState) {
          $('body').removeClass('noscroll ' + modalOnState );
          $('body').unbind('touchmove');
        }

        // Smooth Scrolling Link
        $('.smooth-scroll').on('click', function(){
          $('html, body').animate({scrollTop: $(this.hash).offset().top}, 500);
          return false;
        });

        // Modal Restaurant Menus
        $(".js-nav-menu > a").click(function(e){
          e.preventDefault();
          modalOn('modal-on-state-restaurant');
        });

        // Modal Reservation
        $(".book-reservation").click(function(e){
          e.preventDefault();
          modalOn('modal-on-state-reservation');
        });

        // Modal Close Button
        $(".modal-close").click(function(){
          modalOff('modal-on-state-restaurant modal-on-state-reservation');
        });

        // Form Select
        $("select").each(function() {
          $(this).wrapAll("<div class='select-wrapper'></div>");
        });

        // Responsive Tables
        $('table').addClass('table');

        $(".table").each(function(){
          $(this).wrapAll("<div class='table-wrapper'></div>");
        });

        $("table.table").click(function(){
          $(".table-wrapper").toggleClass( $(this).attr("class") );
        });


        //Responsive videos
        function wrapIFrame(iframe){
          $(iframe).wrapAll("<div class='video-container'></div>");
        }


        //$('iframe[src*="youtube.com"]').each(wrapIFrame(this));
        //$('iframe[src*="vimeo.com"]').each(wrapIFrame(this));

        //Responsive videos
        // function wrapIFrame(iframe){
        //   $(iframe).wrapAll("<div class='video-container'></div>");
        // }

        // $("iframe[src*='youtube']").each(wrapIFrame(this));
        // $("iframe[src*='vimeo']").each(wrapIFrame(this));
        ////////////////////////////////
        

        // Detect when an image or icon is inside an <a> tag
        $('a').has('img, i').addClass('link-img');


        // Enable scroll on Google map until container is clicked
        $('#google-map-container').click(function () {
          $('#google-map-container iframe').css("pointer-events", "auto");
        });

        // Disable scroll on Google map again when leaving the container
        $('#google-map-container').mouseleave(function() { 
          $('#google-map-container iframe').css("pointer-events", "none"); 
        }); 
      },
      finalize: function() {
      }
    },
    'home': {
      init: function() {

        // var currentTime = new Date();
        // var days = [
        //     'Sunday',
        //     'Monday',
        //     'Tuesday',
        //     'Wednesday',
        //     'Thursday',
        //     'Friday',
        //     'Saturday'
        // ];

        // weekday = currentTime.getDay();
        // hour    = currentTime.getHours();
        // min     = currentTime.getMinutes();

       // alert ('The current time is: ' + days[weekday] + ', ' + hour + ':' + min);

          

        // Page Header Logo Image
        $('.page-header .subheader p:first-of-type img').addClass('logo');
        $('.page-header .subheader p:first-of-type img').wrapAll("<div class='img-wrapper'></div>");
          
      }
    },
    'about': {
      init: function() {
        // Page Header Logo Image
        $('.page-header .subheader p:first-of-type img').addClass('logo');
        $('.page-header .subheader p:first-of-type img').wrapAll("<div class='img-wrapper'></div>");
      
        $('.js-more-media').hide();
        $('.open-js-more-media').click(function () {
          $(this).fadeOut("fast");
          $('.js-more-media').slideDown("slow");
        });
      }



    },
    'single_menu': {
      init: function() {

        $('#toggle-about-menu').click(function () {
          $(this).toggleClass('about-content-open');
          $('#menu-about .about-content').slideToggle(1500);
        });

        $(".dining-room-submenu").appendTo(".page-header .page-header--content");
        $(".red-bistro-submenu").appendTo(".page-header .page-header--content");

      }
    },
    'blog': {
      init: function() {
        $('.blog-content').find('.type-post:first-of-type').append("<hr />");
        $('.blog-content').find('.wp-pagenavi').prepend("<hr />");
      }
    },
    'has_video_header': {
      init: function(){

        var tag =  document.createElement('script');
            tag.src = "https://youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        var videoID = $('.js-video-header').data('video-id');
        var player;

        function onYouTubeIframeAPIReady() {
          window.player = new YT.Player('js-player', {
            videoId: videoID,
            events: {
              'onReady': window.onPlayerReady,
              'onStateChange': onPlayerStateChange
            },
            playerVars: {rel:0}
          });
        }
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
        $('body').addClass('video-hidden');

        $('.video-hidden .js-video-header').click(function(){
          $('body').removeClass('video-hidden');
          $('body').addClass('video-playing');
          window.setTimeout(window.player.playVideo(), 300);
        });
        function onPlayerStateChange(event){
          console.log(event);
          if(event.data === 2){ //paused
            $('body').removeClass('video-playing');
            $('body').addClass('video-hidden'); 
          }
        }
        $(window).scroll(function() {
          if (!$('.has-video').isOnScreen()) {
            window.player.pauseVideo();
          }
        });

      }
    },
		'gallery': {
			init: function(){
				var original_list = $('.photo-library-grid').html();

				function initLightbox(){
					baguetteBox.run('.photo-library-grid', {
						noScrollbars: true,
						overlayBackgroundColor: 'rgba(0,0,0,.95)'
					});
				}

				function applyChecks(){
					$('.photo-library-grid').html(original_list);
          $('.photo-library-grid a').hide();
          $('ul.gallery-filter').find('input:checked').each(function(){
            var mediaCatID = $(this).parent().data('media-cat-id');
            $('.photo-library-grid a[data-media-cat-id*="'+mediaCatID+'"]').show();
          });
					$('.photo-library-grid a:hidden').remove();
					initLightbox();
        }

        applyChecks();

        $('ul.gallery-filter li input').change(function(){
					baguetteBox.destroy('.photo-library-grid');
          applyChecks();
        });

        $('ul.gallery-filter li .cmn-toggle:checked').each(function() {
          $(this).parents('.switch').addClass('isChk');
        });

        $("ul.gallery-filter li .cmn-toggle").click( function(){
          if( $(this).prop('checked') === true ){
            $(this).parents('.switch').addClass('isChk');
          } else {
            $(this).parents('.switch').removeClass('isChk');
          }
        });

			}
		}
  };

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
