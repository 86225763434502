// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

(function($){
  $(function(){

    // Add any additional selectors that you
    // would like to apply this behavior to.

    var $images = $('img.cover');
    $images.addClass('img-cover');

    function resize() {
      $images.each(function(){
        var $img = $(this);

        $img.removeClass( 'wide' );
        var ir = $img.outerWidth() / $img.innerHeight();

        var $con = $img.parent();
        var cr = $con.outerWidth() / $con.innerHeight();

        $img.toggleClass( 'wide', ir > cr );
      });
    }

    $(window).on( 'resize', resize );
    $images.load( resize );
    resize();

  });
  $.fn.extend({
    isOnScreen: function(){
        var win = $(window);
        
        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();
        
        var bounds = this.offset();
        bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();
        
        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    }
  });
})(jQuery);

